import captureException from '@monorepo/shared/lib/error/capture-exception';
import React from 'react';

type Props = {
    children: React.ReactNode;
};

const errorsToRefresh = [
    'WebSocket connection failed for host: wss://relay.walletconnect.com',
    'Socket stalled when trying to connect to wss://relay.walletconnect.com',
];

const errorsToIgnore = [];

export default class ErrorBoundary extends React.Component<Props> {
    componentDidMount() {
        window.addEventListener('unhandledrejection', this.catchPromiseRejection);
        window.addEventListener('error', this.catchError);
    }

    componentWillUnmount() {
        window.removeEventListener('unhandledrejection', this.catchPromiseRejection);
        window.removeEventListener('error', this.catchError);
    }

    componentDidCatch(error: Error) {
        this.captureException(error);
    }

    catchError = (event: ErrorEvent) => {
        const error = event.error != null ? event.error : event;
        this.captureException(error);
    };

    catchPromiseRejection = (event: PromiseRejectionEvent) => {
        const error = event.reason;
        this.captureException(error);
    };

    captureException = (error: Error) => {
        if (errorsToIgnore.includes(error.message)) return;

        captureException(error, { id: 'error_boundary' });

        if (errorsToRefresh.includes(error.message)) {
            window.location.reload();
        }
    };

    render() {
        return this.props.children;
    }
}
