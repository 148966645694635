export const IS_DEV_ENV = process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production';

export const RELEASE = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA || 'development';

export const SENTRY_DSN =
    process.env.SENTRY_DSN ||
    process.env.NEXT_PUBLIC_SENTRY_DSN ||
    'https://1e81d3ce32989f8e6229bcc875fce035@o1201476.ingest.us.sentry.io/4507192719704064';

export const discordLink = 'https://discord.com/invite/rumblekongleague';

export const MENU = [
    {
        name: "WHAT IS FAME",
        path: "#what",
    },
    {
        name: "RUMBLE KONG",
        path: "#rumble-kong",
    },
    {
        name: "ATHLOS",
        path: "#athlos",
    },
    {
        name: "FAME ECOSYSTEM",
        path: "#ecosystem",
    },
    {
        name: "PARTNERS",
        path: "#partners",
    },
];
