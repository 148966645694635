import '../style/reset.css';
import '../style/variables.css';

import { NextPage } from 'next';
import { AppProps } from 'next/app';
import React, { ReactElement, ReactNode } from 'react';

import ErrorBoundary from '../components/error-boundary';

type NextPageWithLayout = NextPage & {
    getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
    Component: NextPageWithLayout;
};

const App = ({ Component, pageProps }: AppPropsWithLayout) => {
    const getLayout = Component.getLayout ?? ((page) => page);

    // @ts-ignore
    return <ErrorBoundary>{getLayout(<Component {...pageProps} />)}</ErrorBoundary>;
};

export default App;

