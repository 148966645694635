import * as Sentry from '@sentry/nextjs';

export interface ExceptionData {
    [key: string]: any;
    id: string;
}

const nonErrors = [
    'Signature header needs to be provided to make this call',
    'Network request failed',
];

export default function captureException(error: any, data: ExceptionData) {
    if (typeof window !== 'undefined') {
        console.warn('Captured exception: ', data.id);
        console.error(error);

        Sentry.withScope((scope) => {
            scope.setTag('error_id', data.id);
            delete data.id;

            const level = nonErrors.includes(error?.message) ? 'log' : 'error';
            scope.setLevel(level);

            Object.entries(data).map(([key, value]) => scope.setExtra(key, value));

            Sentry.captureException(error);
        });
    } else {
        console.error(error);
    }
}
